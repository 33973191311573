import axios from 'axios';
import media from '../Assets/bgMedia.png'
import { firebaseApiKey, firebaseServerKey } from '../Constant/Url';
import moment from 'moment';
import messaging from '../firebase.config';
import { getToken } from 'firebase/messaging';
import MyToast from './MyToast';
import { store } from '../store';

const commonKey = store?.getState()?.common.commonKey;

/* Detect Browser */
const detectBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('chrome')) {
        return 'Chrome';
    } else if (userAgent.includes('firefox')) {
        return 'Firefox';
    } else if (userAgent.includes('safari')) {
        return 'Safari';
    } else if (userAgent.includes('edge')) {
        return 'Edge';
    } else if (userAgent.includes('trident') || userAgent.includes('msie')) {
        return 'IE';
    } else {
        return 'Unknown';
    }
};

export const browserName = detectBrowser();

export const getLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coordinates = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    resolve(coordinates);
                    return coordinates;
                },
                (error) => {
                    // Enhanced error handling
                    let errorMessage = '';
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "User denied the request for Geolocation.";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "Location information is unavailable.";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "The request to get user location timed out.";
                            break;
                        case error.UNKNOWN_ERROR:
                            errorMessage = "An unknown error occurred.";
                            break;
                        default:
                            errorMessage = error.message;
                            break;
                    }
                    MyToast("e", errorMessage);
                    reject(new Error(errorMessage));
                },
                {
                    timeout: 10000, // Set timeout for better UX
                    maximumAge: 0,  // Force to get current location
                }
            );
        } else {
            MyToast("e", "Geolocation is not supported by this browser");
            reject(new Error("Geolocation not supported"));
        }
    });
};


/* Getting Time Zone */
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateTimeFormat = (value, format) => {
    let myDate = moment(value).format(format ? format : "MM/DD/YYYY hh:mm")
    return myDate
}

/* Accept Number */
export const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow only numeric characters (0-9)
    if (!/^[0-9]+$/.test(keyValue)) {
        event.preventDefault();
    }
};

/* Formatted Address */
export const getAddress = (data) => {
    const formattedAddress = `${data?.city} ${data?.stateName}, ${data?.country}, ${data?.zipCode}`
    return formattedAddress
};

/* Pagination Button */
export const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
        return <b className='text-white'>PREV</b>;
    }
    if (type === 'next') {
        return <b className='text-white'>NEXT</b>;
    }
    return originalElement;
};

export const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber === undefined) return "--";
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Apply US phone number format (###) ###-####
    const formattedNumber = numericPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formattedNumber;
};

export const getCityName = async ({ lat, lng }) => {
    try {
        // const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=${commonKey?.google_place_key}`);
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
                latlng: `${lat},${lng}`,
                sensor: true,
                key: commonKey?.google_place_key,
            },
        });
        console.log('API Response:', response.data); // Inspect response
        if (response?.status === 200) {
            const results = response.data.results;
            let address = response?.data?.results?.[0]?.formatted_address
            if (results.length > 0) {
                const addressComponents = results[0].address_components;
                console.log('addressComponents', addressComponents)
                const city = addressComponents.find(component => component.types.includes('locality'))?.long_name;
                const postalCode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name;
                const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
                const state = addressComponents.find(component => component.types.includes('administrative_area_level_1'))?.long_name;

                const data = {
                    lat: lat,
                    lng: lng,
                    city: city,
                    state: state,
                    country: country,
                    zipCode: postalCode,
                    address: address
                }
                return data;
            } else {
                console.error('No address details found for the provided coordinates.');
            }
        } else {
            console.error('Failed to fetch address details:', response?.statusText);
        }
    } catch (err) {
        console.error('Error fetching data:', err);
    }
};

export const disabledFutureDate = current => {
    // Disable all dates after today
    return current && current > moment().endOf('day');
};
export const disabledPastDate = current => {
    // Disable all dates after today
    return current && current < moment().startOf('day');
};

export const isWhitespace = (value) => /^\s*$/.test(value);

export const signUpFormInitialValue = {
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    phone: "",
    preferred_spa: [],
    password: "",
    confirmPassword: "",
    country: "",
    state: "",
    city: "",
    remember: true,
}
export const dateFormat = `DD/MM/YYYY`;

// Other Browsers Notifications Support Check
export const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
        return;
    }
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            console.log("Notification Granted");
        } else if (permission === 'denied') {
            console.log("Notification Denied");
        } else if (permission === 'default') {
            console.log("Notification Prompt Dismissed");
        }
    } catch (error) {
        console.error("Error requesting notification permission:", error);
    }
};

export const getFCMToken = async () => {
    const token = await getToken(messaging, { vapidKey: firebaseApiKey })
    return token
}

/* Fun to Subscribe Topic */
export const subscribeTokenToTopic = (token, topic) => {
    /* Perticulat User */
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key=' + `${firebaseServerKey}`
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
    }).catch(error => {
        console.error(error);
    });

    /* Global SPA Owner */
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + 'ToSpaOwner', {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key=' + `${firebaseServerKey}`
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
    }).catch(error => {
        console.error(error);
    });

    /* Global Customer */
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + 'ToCustomer', {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'key=' + `${firebaseServerKey}`
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
    }).catch(error => {
        console.error(error);
    });
};

/* Fun to unsubscribe Topic */
export const unsubscribeTokenFromTopics = (token, topic) => {
    /* Perticulat User */
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': 'key=' + `${firebaseServerKey}`
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error unsubscribing from topic: ' + response.status + ' - ' + response.text();
        }
    }).catch(error => {
        console.error(error);
    });

    /* Global SPA Owner */
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + 'ToSpaOwner', {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': 'key=' + `${firebaseServerKey}`
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error unsubscribing from topic: ' + response.status + ' - ' + response.text();
        }
    }).catch(error => {
        console.error(error);
    });

    /* Global Customer */
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + 'ToCustomer', {
        method: 'DELETE',
        headers: new Headers({
            'Authorization': 'key=' + `${firebaseServerKey}`
        })
    }).then(response => {
        if (response.status < 200 || response.status >= 400) {
            throw 'Error unsubscribing from topic: ' + response.status + ' - ' + response.text();
        }
    }).catch(error => {
        console.error(error);
    });
};


export const playNotificationSound = () => {
    const audio = new Audio('../Assets/notification.wav');
    audio.play().catch(error => {
        console.error('Error playing audio:', error);
    });
};



/*  Get Distance in miles */
const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
}

export const haversineDistance = (coords1, coords2) => {
    const R = 3958.8; // Radius of the Earth in miles
    const lat1 = toRadians(coords1?.[1]);
    const lat2 = toRadians(coords2?.[1]);
    const lon1 = toRadians(coords1?.[0]);
    const lon2 = toRadians(coords2?.[0]);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a = Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    console.log("object", R * c);

    return Math.round(R * c);
}

/* CSS Inline Styling */

/* Colors */
export const blackColor = `#000`
export const whiteColor = `#fff`
export const lightGrayColor = `#a9a9a9`
export const darkGrayColor = `#272727`
export const charcoalColor = `#1b1b1b`
export const jetBlackColor = `#161616`
export const blackBeanColor = `#0c0c0c`
export const blueThemeColor = `#008dbf`
export const blackInputColor = `#111111`
export const blackDarkColor = `#1b2226`
export const pendingColor = `#D9A001`
export const rejectColor = `#D20000`
export const acceptColor = `#0CB700`
export const starColor = `#ffc602`
export const cancelButtonColor = `#ff4747`

export const reactIconStyle = {
    fontSize: 20,
    color: blueThemeColor,
    cursor: 'pointer'
}

export const headerStyle = {
    height: 120,
    paddingInline: 110,
    paddingLeft: 75,
    paddingTop: 10,
    zIndex: 12,
};

export const contentStyle = {
    minHeight: 120,
    padding: '35px 100px 35px 30px',
    maxHeight: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    background: `url(${media})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: whiteColor,
    backgroundColor: browserName === "Safari" ? blackBeanColor : blackColor,

    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    OBackgroundSize: 'cover',
    backgroundSize: 'cover',
};